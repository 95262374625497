<template>
    <div class="panel">
        <h2>Introduction</h2>
        <p>
            Tronwallet, the wallet for the TRON blockchain, currently boasts over 270 million global users who have
            activated and are actively using the platform. TRON blockchain is trusted by numerous Web3 users for its
            security, transparency, and stability. The TRON wallet plugin supports EVM-compatible networks (Ethereum,
            BSC, and BTTC), enabling seamless Web3 experiences. It allows easy connections to DApps and smooth on-chain
            interactions.<br /><br />

            Tronwallet offers exceptional, comprehensive protection for your cryptocurrency assets. It ensures full
            security from the operating environment to transaction data, safeguarding your digital assets at every
            step.<br /><br />

            In the era of the internet, data security is a critical concern for everyone. Encryption technology
            transforms raw data into a form that only the intended recipient can decrypt, ensuring secure data
            transmission. For digital currency transactions, encryption not only protects the identity information of
            the parties involved but also guarantees the integrity and immutability of the transactions.<br />
        </p>

        <div class="shortcut">
            <button type="button" class="button-large" @click="scrollDown">Start now</button>
        </div>

        <h2>Security</h2>
        <p>
            Based on the Tronwallet wallet generation algorithm, you can customize the suffix of your wallet address,
            and the AI will calculate and generate the unique address. When generating a TRON wallet, it is important to
            note that the use of the number "0," the letter "O," and the lowercase letter "l" is not supported, as these
            characters are easily confused. These characters are blocked by the TRON blockchain to ensure clarity and
            avoid errors.<br /><br />

            You are free to set your desired suffix for the wallet address (note: we recommend a maximum of 5 characters
            to ensure a quicker generation process). Longer suffixes will result in increased computation time.<br /><br />

            We utilize dual encryption technology and support offline generation, ensuring the utmost security for your
            TRON wallet private key. For enhanced safety, it is recommended to select offline mode during the generation
            process. This will allow you to securely create your exclusive Tronwallet address, giving you complete
            control and protection over your digital assets.<br />
        </p>

        <h2>Scenarios</h2>
        <p>
            By using Tronwallet to generate a custom wallet address with the suffix "88888," you create an exclusive
            identity wallet. This unique address stands out during transactions and transfers, much like having a phone
            number ending in "88888"—it attracts admiration and sets you apart. Such a personalized address becomes a
            symbol of prestige and individuality within the blockchain community.<br /><br />

            For a project like "999.com," you can use Tronwallet to generate a wallet address with the suffix "999."
            This address acts as a special identifier for the project, making it perfect for receiving payments,
            managing funds, or showcasing the project’s treasury. The customized wallet address creates a distinct
            project identity, and in the Web3 space, it helps the project gain visibility, credibility, and a "cool"
            factor among users and investors.<br />
        </p>

        <h2>Examples for Illustration</h2>
        <div class="p">
            The TRON Tronwallet address consists of a 34-character combination of letters and numbers. You can customize
            the suffix of your wallet address by choosing specific letters or numbers.<br /><br />

            The computation time required to generate the address depends on the length of the suffix you choose. For
            faster generation, we recommend customizing the suffix to 4 to 5 characters. Longer suffixes will increase
            the time required for the address generation process.<br /><br />

            This flexibility allows you to create a personalized and unique TRON wallet address that suits your
            preferences, while maintaining an efficient generation time.<br />
            Examples:
            <ul>
                <li><span class="monospace">TDqSquXBgUCLYvYC4XZgrprLK589dkhSCf</span></li>
                <li><span class="monospace">TDqSquXBgUCLYvYC4XZgrprLK589d88888</span></li>
            </ul>
        </div>

        <p>
            Using the Tronwallet AI algorithm, an address with the suffix "88888" will be generated, creating a wallet
            that is exclusively yours.<br /><br />

            For maximum security, it is recommended to generate the wallet offline (yes, offline mode is more secure).
            Once generated, you can copy the private key and import it into your mobile wallet software. This will allow
            you to activate and use your TRON wallet with the unique address ending in "88888."<br /><br />

            This offline generation process ensures the highest level of security for your private key, keeping your
            digital assets safe and private.<br />
        </p>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                scrollTimeOut: null,
            };
        },
        methods: {
            scrollDown() {
                this.scrollTo(document.getElementById('input-panel'), -1);
            },
            scrollTo(element, lastValue) {
                let currentValue = window.scrollY;
                let diff = element.getBoundingClientRect().top / 6;
                if (Math.abs(diff) > 1 && currentValue > lastValue) {
                    window.scrollTo(0, window.scrollY + diff);
                    this.scrollTimeOut = setTimeout(this.scrollTo, 30, element, currentValue);
                } else if (currentValue >= lastValue) {
                    document.getElementById('input').focus();
                }
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "../css/variables"
    p, .p
        margin: 15px 0 20px
        color: $text-alt
        overflow-x: hidden
        text-overflow: ellipsis
        .monospace
            font-family: $monospace-font
            font-size: 0.85em
    .shortcut
        text-align: center
        .button-large
            width: 150px
            margin: 15px 0 35px
</style>
